.c-container {
  padding-left: 5vw;
  padding-right: 5vw;
  min-height: 350px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.c-img {
  width: 100%;
}

.c-game-thumbnail {
  max-height: 350px;
}

.c-main {
  margin-bottom: 24px;
  padding: 24px;
  background-color: #132a13;
  text-align: center;
  min-height: 380px;
  width: 100%;
  color: #ecf39e;
}

.c-footer {
  background-color: #132a13;
  color: #ecf39e;
  height: 200px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.c-button-w {
  width: 100%;
  max-width: 500px;
}

.swiper-button-next {
  color: black;
}

.swiper-button-prev {
  color: black;
}